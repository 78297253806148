.alert {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  & > div {
    width: 100%;
    background-color: #fff;

    &.body {
      padding: 32px 20px 24px;
      width: 100%;
      height: 100%;
      text-align: center;
      word-break: keep-all;

      .icon {
        width: 60px;
        height: 60px;
        margin: 0 auto;
      }
    }

    .border {
      height: 1px;
      margin: 24px 0;
      background-color: #f7f8f9;
    }

    .btn {
      margin-top: 24px;
      width: 100%;
      height: 44px;
      border-radius: 8px;
      background: #ff6900;
      font-size: 16px;
      color: #fff;
      text-align: center;
    }
  }
}

.outerNotify {
  margin-top: 20px;
  text-align: center;

  button {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    color: #fff;
    font-size: 14px;
    line-height: 25px;
    border-bottom: solid 1px #fff;
  }
}
