.headerWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  z-index: 500;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;

  &.hidden {
    position: relative;
    visibility: hidden;
    opacity: 0;
  }
  &.displaynone {
    display: none;
  }
  &.hiddenLine {
    border-bottom: none;
  }
  &.hiddenBg {
    background-color: rgba(255, 255, 255, 0);
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    height: 56px;
    padding: 0 8px;
    text-align: center;
    z-index: 9;

    .left {
      text-align: left;

      &.hidden {
        position: relative;
        visibility: hidden;
      }
    }

    .center {
      width: calc(100% - 100px);
      text-align: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      &.titleLeft {
        text-align: left;
      }
    }

    .right {
      text-align: right;
    }

    .title {
      max-width: 100%;
      font-size: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 56px;
      margin: 0 auto;

      &.hiddenTitle {
        opacity: 0;
      }
    }
  }
}

.storeShare {
  background-color: #000 !important;
  button {
    color: white !important;
  }
}

.mainHeader {
  border-bottom: 1px solid rgba(246, 246, 246, 0);
  &.borderShow {
    border-bottom: 1px solid rgba(246, 246, 246, 1);
  }

  .top {
    display: flex;
    align-items: center;

    .searchBar {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 38px;
      border: 2.4px solid #ff6900;
      border-radius: 4px;

      .searchBtn {
        width: 38px;
        text-align: center;
      }
    }
  }
}

.searchPopupWarp {
  top: 0;
  min-height: 100vh;
  .searchPopup {
    display: block !important;
    width: 100vw !important;
    max-width: 750px !important;
    max-height: 100vh !important;
    overflow: hidden;
    overflow-y: auto;
  }
}
