@font-face {
  font-family: 'text-security-disc';
  src: local('text-security-disc'),
    url('https://common.joongna.com/font/text-security-disc/text-security-disc.eot');
  src: url('https://common.joongna.com/font/text-security-disc/text-security-disc.eot?#iefix')
      format('embedded-opentype'),
    url('https://common.joongna.com/font/text-security-disc/text-security-disc.woff2')
      format('woff2'),
    url('https://common.joongna.com/font/text-security-disc/text-security-disc.woff')
      format('woff'),
    url('https://common.joongna.com/font/text-security-disc/text-security-disc.ttf')
      format('truetype'),
    url('https://common.joongna.com/font/text-security-disc/text-security-disc.svg#text-security')
      format('svg');
}
