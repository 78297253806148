.imgWrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  &.circle {
    border-radius: 50%;
  }

  img {
    position: absolute;
    height: 100%;
    width: auto;
    max-width: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.heightLong {
      height: auto;
      width: 100%;
    }
  }

  .bannerTitle {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    font-size: 20px;
    color: #fff;
  }
}

.modal {
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  margin: 0;

  & > div {
    width: 100%;
    height: 100%;
    background-color: black;
  }

  .Header {
    border: 0;
    width: 100%;
    max-width: 750px;
    height: 50px;
    margin: 0 auto;
    z-index: 50;

    & > button {
      span {
        &:first-child {
          font-size: 1.6rem;
          color: white;
        }
      }
    }
  }

  .modalBody {
    position: initial;

    .modalImage {
      position: absolute;
      width: 100%;
      max-width: 750px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
