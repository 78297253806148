// @import '../fonts/SpoqaHanSans-kr.css';
@import '../fonts/SpoqaHanSansNeo-kr.css';

@import '../fonts/text-security-disc.css';

%spoqa {
  font-family: 'Spoqa Han Sans Neo', 'Sans-serif';
}

%text-security-disc {
  font-family: 'text-security-disc';
}
