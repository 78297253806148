/* ---------------------------- */
/* ==border==------------------ */
/* ---------------------------- */
@mixin border-style($direction, $thick, $color) {
  border-#{$direction}: $thick solid $color;
}
/* ---------------------------- */
/* ==icon==-------------------- */
/* ---------------------------- */
@mixin icon-set($name, $width, $height, $bg-size, $direction) {
  .ic_#{$name} {
    display: inline-block;
    width: $width;
    height: $height;
    background-size: $bg-size auto;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 0;
    vertical-align: middle;
    background-image: url('../../img/icon/ic_#{$name}.png');

    &.#{$direction} {
      @if $direction == right {
        transform: rotate(0deg);
      }
      @if $direction == down {
        transform: rotate(90deg);
      }
      @if $direction == left {
        transform: rotate(180deg);
      }
      @if $direction == up {
        transform: rotate(270deg);
      }

      @if $direction == none {
        transform: rotate(0deg);
      }
    }
  }
}
/* ---------------------------- */
/* ==button==------------------ */
/* ---------------------------- */
@mixin btn-pack($type, $color, $height) {
  .btn_pack_#{$type} {
    @extend %btn-pack;

    @if $type == 'line' {
      border-width: 1px;
      border-style: solid;

      &.#{$color} {
        @if $color == 'gray' {
          border-color: $middle-gray;

          .btn_txt {
            color: $gray;
          }
        }
      }
    }
    @if $type == 'solid' {
      &.#{$color} {
        @if $color == 'orange' {
          background: $brand;

          .btn_txt {
            color: $white;
          }

          &.disabled {
            background: $middle-gray;
          }
        }
      }
    }
    &.#{$height} {
      @if $height == 'h28' {
        height: 28px;
      }
      @if $height == 'h52' {
        height: 52px;
      }
    }
  }
}
