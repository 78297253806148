.share {
  max-width: 290px !important;

  .closeBtn {
    width: 100%;
    height: 50px;
    background: #000;
    color: #fff;
    font-size: 17px;
  }
}
