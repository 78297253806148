@keyframes fadeInNOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3%;
  }
}

.logoLoading {
  &.isPage {
    max-width: 750px;
    margin: 0 auto;
    padding: 15px 20px;
    min-height: 100vh;
  }
  position: relative;
  background-color: #fff;
  text-align: center;
  img {
    position: relative;
    top: 150px;
    width: 54.5%;
    animation-name: fadeInNOut;
    animation-duration: 0.7s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: alternate;
  }
}
