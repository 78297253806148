/*
  width
*/

.w_auto {
  width: auto !important;
}

.w100v {
  width: 100vw !important;
}

@each $width in 0 25 50 100 {
  .w#{$width} {
    width: #{$width}% !important;
  }
}

@each $col in 1 2 3 4 5 {
  .col#{$col} {
    width: (100% / #{$col}) !important;
  }
}

/*
  height
*/

.mh100v {
  min-height: 100vh !important;
}

@each $height in 0 25 50 100 {
  .h#{$height} {
    height: #{$height}% !important;
  }
}

@each $height in 0 25 50 100 125 150 {
  .h#{$height}px {
    height: #{$height}px !important;
  }
}
