/*---------------------*/
/*---- auto jsr ----*/
/*---------------------*/

.jsr_rail {
  height: 6px;
  background: $Cf1f1f1;

  &:before,
  &:after {
    width: 3px;
    height: 6px;
    margin: 0;
    background: $Cf1f1f1;
    top: 10px;
  }
  &:before {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    left: -3px;
  }
  &:after {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    right: 0;
  }
}

.jsr_bar {
  height: 6px;
  border-radius: 3px;
  background: rgba(62, 91, 221, 1);
  top: 10px;
}
