.pop {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;

  .inputWrap {
    display: flex;
    align-items: center;

    .label {
      min-width: 65px;
      padding-left: 16px;
    }

    .input {
      padding: 16px;
      border: none;
      font-size: 14px;
      color: #333;
      background: none;
      -webkit-appearance: none;

      &::placeholder {
        color: #c5c5c5;
      }
    }
  }
  .footer {
    display: flex;

    button {
      width: 100%;
    }
  }
}

.option {
  display: flex;
  margin-top: 15px;
  align-items: center;

  & > button {
    width: 50%;
    padding: 0 10px;
  }

  & > div {
    height: 14px;
    border-left: 1px solid #ffffff;
  }
}

.addSearch {
  width: 100vw !important;
  max-width: 750px !important;

  & .addSearchComponent {
    position: fixed;
    width: 100%;
    max-width: 750px;
    max-height: 100vh !important;
    margin: 0 auto;
    height: 100% !important;
    top: 50px;
    left: 0;
    right: 0;
  }
}
