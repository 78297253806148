body {
  padding: 0;
  margin: 0;
  background: #e1e1e1;
  @extend %spoqa;
  font-size: 0;
  color: $black;
  box-sizing: border-box;
  position: relative;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;

  *,
  input,
  button {
    color: inherit;
    font-weight: inherit;
    @extend %spoqa;
    box-sizing: border-box;
    letter-spacing: -0.3px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
  }

  &.lockBody {
    height: 100vh;
    overflow: hidden;
  }
}

em,
strong {
  font-style: normal;
  font-weight: 700;

  &.semi {
    font-weight: 500;
  }
  &.extra {
    font-weight: 900;
  }
}

/*
  display
*/
.dis_none {
  display: none !important;
}

.dis_block {
  display: block !important;
}

.dis_inline_block {
  display: inline-block !important;
}

.display_f {
  display: flex !important;
}

.hidden {
  visibility: hidden !important;
}

.visible {
  visibility: visible !important;
}

.over_hidden {
  overflow: hidden !important;
}
.over_visible {
  overflow: visible !important;
}

.fixedTop {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  z-index: 100;
}

/* ---------------------------- */
/* ==overflow==-------------- */
/* ---------------------------- */
.overflow_h {
  overflow: hidden;
}
.overflow_a {
  overflow: auto;
}
.overflow_v {
  overflow: visible;
}

/* ---------------------------- */
/* ==background==-------------- */
/* ---------------------------- */
// background-color type
.bg_white {
  background-color: $white !important;
}

.bg_gray {
  background-color: $gray !important;
}

.bg_brand {
  background-color: $brand !important;
}

.bg_xx_lightgray {
  background-color: $xx-light-gray !important;
}

.bg_x_lightgray {
  background-color: $x-light-gray !important;
}

.bg_0f1013 {
  background-color: #0f1013 !important;
}

.bg_cacdd0 {
  background-color: #cacdd0 !important;
}

/* ---------------------------- */
/* ==text==-------------------- */
/* ---------------------------- */
// text color type
.c_white {
  color: $white !important;
}

.c_black {
  color: $black !important;
}

.c_orange {
  color: $brand !important;
}

.c_green {
  color: #47c482 !important;
}

.c_red {
  color: #fc4731 !important;
}

.c_gray {
  color: $gray !important;
}

.Cc5c5c5 {
  color: $Cc5c5c5 !important;
}

.C333333 {
  color: $C333333 !important;
}
.C606060 {
  color: $C606060 !important;
}
.C666666 {
  color: #666 !important;
}
.C767676 {
  color: $C767676 !important;
}
.C979797 {
  color: $C979797 !important;
}
.C9d9d9d {
  color: $C9d9d9d !important;
}
.Ca3a3a3 {
  color: #a3a3a3 !important;
}
.Cf85454 {
  color: $Cf85454 !important;
}
.C9297b1 {
  color: #9297b1 !important;
}
.Cda1e28 {
  color: #da1e28 !important;
}
.C5f5f5f {
  color: #5f5f5f !important;
}
.Ca5a5a5 {
  color: #a5a5a5 !important;
}
.C0a0400 {
  color: #0a0400 !important;
}
.c_green {
  color: $success !important;
}

// text align type
.align_l {
  text-align: left;
}
.align_c {
  text-align: center;
}
.align_r {
  text-align: right;
}
.vertical_t {
  vertical-align: top;
}
.vertical_m {
  vertical-align: middle;
}

// 텍스트 생략
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ellipsis2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  word-break: break-all;
}

// text size type
@each $fontsize in 0 10 11 12 13 14 15 16 17 18 20 22 24 26 50 {
  .f#{$fontsize} {
    font-size: #{$fontsize}px !important;
  }
}

.lighter {
  font-weight: lighter;
}

.bold {
  font-weight: bold;
}

.fw500 {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.lineH20 {
  line-height: 20px;
}

/*---------------------------------------------*/
/*---------------------------------------------*/
/*--- 배경 색상 --------------------------------*/
/*---------------------------------------------*/
/*---------------------------------------------*/
.bg_white {
  background-color: $white !important;
}
.bg_lightgray {
  background-color: $xx-light-gray !important;
}
.bg_lightgray2 {
  background-color: $x-light-gray !important;
}
.bg_black {
  background-color: $black !important;
}
.bg_orange {
  background-color: $brand !important;
}
.bg_mypage {
  background-color: $C0f1013 !important;
}
.bg_auto {
  background-color: $C3e5bdd !important;
}
.bg_custom {
  background-color: $white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@each $zIndex in 5 10 15 20 25 30 35 40 45 50 55 60 {
  .zIndex#{$zIndex} {
    z-index: #{$zIndex} !important;
  }
}

/* ---------------------------- */
/* ==padding ==---------------- */
/* =(어느정도 갖춰지면 믹스인을 사용하자)*/
/* ---------------------------- */

// 패딩 상단
@each $padding
  in 0
  2
  5
  8
  10
  12
  16
  20
  25
  30
  35
  45
  50
  56
  57
  64
  67
  81
  90
  91
  93
  95
  96
  100
  104
  105
  110
  126
  131
  140
  145
  156
  171
  246
{
  .pd_t#{$padding} {
    padding-top: #{$padding}px !important;
  }
}

// 패딩 아래
@each $padding in 0 2 5 6 10 12 14 20 24 25 30 32 40 50 52 55 56 60 62 72 80 {
  .pd_b#{$padding} {
    padding-bottom: #{$padding}px !important;
  }
}

// 패딩 왼쪽
@each $padding in 5 15 17 20 22 30 40 50 {
  .pd_l#{$padding} {
    padding-left: #{$padding}px !important;
  }
}

// 패딩 오른쪽
@each $padding in 5 20 25 30 40 45 50 80 {
  .pd_r#{$padding} {
    padding-right: #{$padding}px !important;
  }
}

// 패딩 위아래
@each $padding
  in 0
  5
  8
  10
  12
  13
  14
  15
  16
  17
  20
  22
  24
  25
  28
  30
  32
  37
  38
  40
  43
  50
  55
  60
  80
  90
{
  .pd_v#{$padding} {
    padding-top: #{$padding}px !important;
    padding-bottom: #{$padding}px !important;
  }
}

// 패딩 양쪽
@each $padding in 0 3 4 8 10 12 14 15 16 20 25 30 38 40 50 60 90 {
  .pd_h#{$padding} {
    padding-left: #{$padding}px !important;
    padding-right: #{$padding}px !important;
  }
}

// 패딩 사방 동일
@each $padding in 0 3 10 12 15 16 20 24 25 30 35 40 {
  .pd#{$padding} {
    padding: #{$padding}px !important;
  }
}

/* ---------------------------- */
/* ==margin ==----------------- */
/* =(어느정도 갖춰지면 믹스인을 사용하자)*/
/* ---------------------------- */

// 마진 상단
@each $margin in 0 5 8 10 12 16 20 24 25 28 30 50 {
  .mt#{$margin} {
    margin-top: #{$margin}px !important;
  }
}

// 마진 하단
@each $margin in 2 5 8 10 12 14 16 18 20 24 25 26 30 40 50 {
  .mb#{$margin} {
    margin-bottom: #{$margin}px !important;
  }
}

// 마진 오른쪽
@each $margin in 1 2 5 8 10 12 18 20 24 25 30 50 {
  .mr#{$margin} {
    margin-right: #{$margin}px !important;
  }
}

// 마진 왼쪽
@each $margin in 2 5 8 10 12 18 20 24 25 30 50 {
  .ml#{$margin} {
    margin-left: #{$margin}px !important;
  }
}

// 마진 사방 동일
@each $margin in 0 10 12 15 20 25 30 35 40 {
  .m#{$margin} {
    margin: #{$margin}px !important;
  }
}

.ml_auto {
  margin-left: auto !important;
}

/* ---------------------------- */
/* ==transform==--------------- */
/* ---------------------------- */
//rotate
.rotate0 {
  transform: rotate(0deg);
}
.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

/* ---------------------------- */
/* ==border-radius==----------- */
/* ---------------------------- */
//rotate
.radius2 {
  border-radius: 2px;
}

.radius4 {
  border-radius: 4px;
}

.radius5 {
  border-radius: 5px;
}
/* ---------------------------- */
/* ==box-shadow==-------------- */
/* ---------------------------- */
//shadow type
.shadow_type1 {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.08);
}

/* ---------------------------- */
/* ==position==---------------- */
/* ---------------------------- */
// position type

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.abs_v_r {
  @extend %ab-center-vertical;
  right: 0;
}
// position type 위아래 중간, 오른쪽에서 얼마나 떨어지는지
@each $right in 0 5 10 15 16 20 48 80 {
  .abs_v_r#{$right} {
    @extend %ab-center-vertical;
    right: #{$right}px;
  }
}
// position type 위아래 중간, 왼쪽 0
.abs_v_l {
  @extend %ab-center-vertical;
  left: 0;
}
// position type 위아래 중간, 왼쪽에서 얼마나 떨어지는지
@each $left in 0 5 10 15 16 20 22 {
  .abs_v_l#{$left} {
    @extend %ab-center-vertical;
    left: #{$left}px;
  }
}

// position type 좌우 중간
.abs_h {
  @extend %ab-center-horizontal;
}

@each $top in 0 {
  .abs_h_t#{$top} {
    @extend %ab-center-horizontal;
    top: #{$top}px;
  }
}

// position type 위아래양옆 중간
.abs_all {
  @extend %ab-center-all;
}

/* ---------------------------- */
/* ==border==------------------ */
/* ---------------------------- */
.border_none {
  border: none !important;
}

.border {
  border: 1px solid $light-gray;
}

.border_t {
  @include border-style('top', 1px, $x-light-gray);
}
.border_b {
  @include border-style('bottom', 1px, $x-light-gray);
}

.devider {
  display: block;
  width: 100%;
  min-height: 10px;
  background: $xx-light-gray;
}

/* ---------------------------- */
/* ==input==-------------------- */
/* ---------------------------- */
// 회색라인 input/selectbox
.input_grayline {
  @extend %input-grayline;
}

.between_input {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.select_arrow {
  width: 50px;
  height: 50px;
  background: inherit;
  pointer-events: none;
}

/* ---------------------------- */
/* ==icon==-------------------- */
/* ---------------------------- */
// 라인 화살표
// arrow_line이며, .arrow_line.down 이런식으로 방향을 교체해서사용
@include icon-set('arrow_line', 16px, 16px, 16px, 'down');
@include icon-set('arrow_line', 16px, 16px, 16px, 'right');
@include icon-set('arrow_line', 16px, 16px, 16px, 'bottom');
@include icon-set('arrow_line', 16px, 16px, 16px, 'left');
// 원형 느낌표 아이콘
@include icon-set('tooltip_exc', 18px, 18px, 18px, 'none');

/* ---------------------------- */
/* ==button==------------------ */
/* ---------------------------- */
// 라인 버튼
@include btn-pack('line', 'gray', 'h28');
@include btn-pack('solid', 'orange', 'h52');

// ratring 작업시 추가된 내용

.abs_t_l {
  position: absolute;
  top: 0;
  left: 0;
}

.abs_b_l {
  position: absolute;
  bottom: 0;
  left: 0;
}

.lh120 {
  line-height: 1.2;
}

.lh150 {
  line-height: 1.5;
}

.ws_pre {
  white-space: pre;
}

.ws_pre_w {
  white-space: pre-wrap;
}

.w_break {
  word-break: break-word;
}

.w_keep_all {
  word-break: keep-all;
}

.ICphone {
  background-image: url('../../../asset/img/icon/credentialPhone.png');
}

.ICnaver {
  background-image: url('../../../asset/img/icon/credentialNaver.png');
}

.ICkakao {
  background-image: url('../../../asset/img/icon/credentialKakao.png');
}

.ICfacebook {
  background-image: url('../../../asset/img/icon/credentialFacebook.png');
}

.ICpayco {
  background-image: url('../../../asset/img/icon/credentialPayco.png');
}

.ICapple {
  background-image: url('../../../asset/img/icon/credentialApple.png');
}

.ICncafe {
  background-image: url('../../../asset/img/icon/credentialNcafe.png');
}

.suspend {
  padding-left: 25px;
  background-image: url('../../../asset/img/icon/ic_suspend_or.png');
  background-size: 21px auto;
  background-position: center left;
  background-repeat: no-repeat;
}

.icon {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  &.paper {
    background-image: url('../../img/icon/ic_paper_line.png');
    background-size: 21px auto;
  }
}

.iconBtn {
  width: 40px;
  height: 40px;
  background-size: 400px auto;
  background-repeat: no-repeat;
  background-image: url('../../../asset/img/icon/sprite_header.png');
  z-index: 1;
  position: relative;

  // 뒤로가기
  &.icBack {
    background-position: 0 0;
  }
  // 뒤로가기 흰색
  &.icBackWhite {
    background-position: -40px 0;
  }
  // 닫기
  &.icClose {
    background-position: -80px 0;
  }
  // 닫기 흰색
  &.icCloseWhite {
    background-position: -120px 0;
  }
  // 설정
  &.icSetWhite {
    background-position: -160px 0;
  }
  // 설정 흰색
  &.icHamberger {
    background-position: -200px 0;
  }
  // 공유
  &.icShare {
    background-position: -240px 0;
  }
  // 공유
  &.icShareWhite {
    background-position: -280px 0;
  }
  // 신고하기
  &.icBadReport {
    background-position: -320px 0;
  }
  // 신고하기
  &.icBadReportWhite {
    background-position: -360px 0;
  }
  // 햄버거 메뉴
  &.icHamberger {
    background-position: 0 -40px;
  }
  // 검색
  &.icSearch {
    background-position: -40px -40px;
  }
  // 검색 검정색
  &.icSearchBlack {
    background-position: -80px -40px;
  }
  // 검색 흰색
  &.icSearchWhite {
    background-position: -120px -40px;
  }
  // 원형 ?
  &.icQuestion {
    background-position: -320px -40px;
  }
  // 새로고침
  &.icReset {
    background-position: -360px 0;
  }
  &.icHome {
    background-image: url('../../../asset/img/190410_symbol_basic.png');
    background-position: center;
    background-size: 40px;
  }

  &.textBtn {
    background: none;
    margin-right: 20px;
    text-align: right;
    font-size: 14px;
  }

  &.waitingCount {
    width: auto;
    background: none;
    margin-right: 20px;
    padding-right: 25px;
    font-size: 14px;
    position: relative;
    > span {
      width: 20px;
      height: 20px;
      margin: auto;
      font-size: 10px;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;

      &:after {
        z-index: -1;
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #ff6900;
      }
    }
  }
}
