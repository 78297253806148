.bannerImg {
  width: 100%;
  height: 290px;
}

.shareStore {
  text-align: center;
  padding-bottom: 20px;
  margin: 0 15px 20px;
  border-bottom: 1px solid #e1e1e1;
}

.shareEvent {
  text-align: center;
  padding-bottom: 20px;
  margin: 0 15px 20px;
  border-bottom: 1px solid #e1e1e1;
}

.shareTitle {
  white-space: nowrap;
  font-size: 15px;
  margin-bottom: 18px;
  text-align: center;
}

.icons {
  display: flex;
  justify-content: space-between;
  padding: 0 55px;
  .icon {
    width: 54px;
    height: 54px;
  }
}

.profileImg {
  position: absolute;
  top: -45px;
  left: calc(50% - 45px);
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
}
