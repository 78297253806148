.z600 {
  z-index: 600;
}

.alert {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  & > div {
    width: 100%;
    background-color: #fff;

    &.body {
      padding: 32px 20px 24px;
      width: 100%;
      height: 100%;
      text-align: center;
      word-break: keep-all;
      line-height: 1.5;
    }

    &.footer {
      display: flex;
      border-top: 1px solid #f1f1f1;

      button {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 13px;
        color: #333;
        text-align: center;
        border-right: 1px solid #f1f1f1;
        vertical-align: middle;

        &.orange {
          color: #ff6900;
        }
        &.blackBg {
          color: #fff;
          background-color: #000;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
