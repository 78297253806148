@import './fonts.scss';
@import 'include/variables';
@import 'include/extend';
@import 'include/mixin';
@import 'include/size';
@import 'include/common';

@import 'include/auto';

/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* =reset--------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
textarea {
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  background: none;
  background: $white;
  color: $black;
  vertical-align: middle;
  -webkit-appearance: none;
  resize: none;

  &::placeholder {
    color: $Cc5c5c5;
  }
}

input {
  -webkit-appearance: none;
  background: white;
}

.dim_popup {
  top: 0;
  left: 0;
  z-index: 101;
}

body {
  &.scrollLock {
    overflow: hidden;
  }
}

.page {
  width: 100vw !important;
  max-width: 750px !important;
  min-height: 100vh !important;
  background-color: #fff !important;
  margin: 0 auto !important;

  &.bg_white {
    background-color: $white !important;
  }

  &.bg_xx_lightgray {
    background-color: $xx-light-gray !important;
  }

  &.bg_x_lightgray {
    background-color: $x-light-gray !important;
  }

  &.bg_0f1013 {
    background-color: #0f1013 !important;
  }
}

.footer {
  flex-grow: 1;
  padding: 20px;
  background-color: #f6f6f6;
  width: 100%;
}

.pageAnimation {
  position: relative;
  left: 0;
  right: 0;
}

.pageAnimation-enter {
  position: absolute;
  opacity: 0;
}

.pageAnimation-enter-active {
  position: absolute;
  opacity: 1;
  transition: opacity 1000ms;
}

.pageAnimation-exit {
  position: absolute;
  opacity: 1;
}

.pageAnimation-exit-active {
  position: absolute;
  opacity: 0;
  transition: opacity 1000ms;
}

// 해당부분 최종 삭제되어야함 아직 overlay_popup을 쓰는 곳이 있어 불가피하게 넣음
.overlay_popup {
  z-index: 100;
}

label {
  margin: 0;
}

strong {
  font-weight: bold;
}

.container {
  padding: 0;
}

.close {
  float: none !important;
  opacity: 1 !important;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.white-space-pre {
  white-space: pre;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
/*---------------------*/
/*---- hamin reset ----*/
/*---------------------*/
button {
  cursor: pointer;
}

// 본문 공통
#jnContent {
  width: 100vw;
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;
  background: $white;
  @extend %spoqa;

  &.bg_xx_lightgray {
    background: $xx-light-gray;
  }
}

//문맥상 필요하나 디자인상 숨김 처리할 때
.screen_out {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0;
  text-indent: -9999px;
}

// '왼쪽: 타이틀 오른쪽: 내용' 인 리스트 형식
.list_condition {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  dt,
  dd {
    vertical-align: top;
  }

  dt {
    width: 30%;
    word-break: keep-all;
  }

  dd {
    width: 70%;
    padding-left: 10px;
  }
}

// 숫자나 기호 들여쓰기한 리스트
.list_desc {
  li {
    position: relative;
  }

  .desc {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.jsr_rail-outer {
  padding: 10px 0;
}
.jsr {
  width: calc(100% - 3px);
}
.jsr_slider {
  width: 26px;
  height: 26px;
}

.text-security-disc {
  @extend %text-security-disc;
}

.dim {
  background-color: rgba(0, 0, 0, 0.08);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* =product detail images ---------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
.swiper-pagination {
  &.numbering {
    width: auto;
    padding: 3px 10px;
    color: #fff;
    background: rgba(15, 16, 19, 0.28);
    font-size: 14px;
    line-height: 15px;
    position: absolute;
    left: 50%;
    bottom: 14px;
    transform: translateX(-50%);
    color: rgba(255, 255, 255, 0.7);
  }
}

// page transition
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}

/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* =grade rating style-------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */

.grade_table {
  width: 100%;
  border-collapse: collapse;
}

.grade_table thead {
  background: #f6f6f6;
}

.grade_table th {
  padding: 12px 5px;
  border-right: 1px solid #f1f1f1;
  font-size: 12px;
  line-height: 1.4;
  font-weight: normal;
  vertical-align: middle;
}

.grade_table thead th:last-child {
  border-right: none;
}

.grade_table tbody th {
  border-bottom: 1px solid #f1f1f1;
  font-size: 0;
}
.grade_table td {
  padding: 10px;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  font-weight: normal;
  vertical-align: middle;
  text-align: center;
  font-size: 0;
}

.grade_table td:last-child {
  border-right: none;
}

.grade_table .badge {
  display: inline-block;
  width: 14px;
  height: auto;
  margin-right: 4px;
  vertical-align: middle;
}

.grade_table span {
  display: inline-block;
  font-size: 12px;
  line-height: 1.4;
  vertical-align: middle;
  word-break: keep-all;
}

.qustion {
  display: inline-block;
  margin-right: 4px;
  width: 16px;
}

// page transition
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
}
