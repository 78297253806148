/* --- color type ------------------------------ */
$white: #fff;
$xx-light-gray: #f6f6f6;
$x-light-gray: #f1f1f1;
$light-gray: #e1e1e1;
$middle-gray: #c5c5c5;
$gray: #979797;
$black: #333;
$brand: #ff6900;
$service-auto: #3e5bd3;
$success: #11c482;

$C0f1013: #0f1013;
$C323232: #323232;
$C505050: #505050;
$C575757: #575757;
$C606060: #606060;
$C767676: #767676;
$C979797: #979797;
$C9d9d9d: #9d9d9d;
$Cc5c5c5: #c5c5c5;
$Ccccccc: #ccc;
$Ce1e1e1: #e1e1e1;
$Ce8e8e8: #e8e8e8;
$Cf9f9f9: #f9f9f9;
$Cf5f5f5: #f5f5f5;
$Cf1f1f1: #f1f1f1;
$Cb8bfd0: #b8bfd0;
$C6f737f: #6f737f;
$C495062: #495062;
$C23262b: #23262b;
$C336df0: #336df0;
$Cf7cc33: #f7cc33;
$Cf85454: #f85454;
$C3e5bdd: #3e5bdd;
$C333333: #333;

$devider-gray: #e1e1e1;

$green: #47c482;
$rank-blue: #336ef0;
$delete-red: #f85454;
$point-yellow: #fdff7c;
$call-green: #53c205;
$mypage-color: #8a92a5;
$mypage-sub-color: #646a7b;

/* --- text set ------------------------------ */
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$bolder: 900;

$txt-left: left;
$txt-right: right;
$txt-center: center;

$txt-justify: justify;
$txt-nowrap: nowrap;

$lowercase: lowercase;
$uppercase: uppercase;
$capitalize: capitalize;

$font-size-xxx-small: 8px !default;
$font-size-xx-small: 10px !default;
$font-size-x-small: 12px !default;
$font-size-small: 14px !default;
$font-size-halfsmall: 15px !default;
$font-size-regular: 16px !default;
$font-size-large: 18px !default;
$font-size-x-larger: 20px !default;
$font-size-xx-larger: 24px !default;
$font-size-xxx-larger: 30px !default;

$line-height-120: 120%;
$line-height-150: 150%;
$line-height-180: 180%;

/* --- media size variable ------------------- */
$mobile-small: 320;
$mobile-large: 480;
$tablet: 750;
$screen: 1024;
