/* ---------------------------- */
/* ==position==---------------- */
/* ---------------------------- */
// absolute 위아래 중간
%ab-center-vertical {
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
// absolute 양옆 중간
%ab-center-horizontal {
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}
// absolute 양옆위아래 중간
%ab-center-all {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* ---------------------------- */
/* ==input==------------------- */
/* ---------------------------- */
// 회색라인 인풋
%input-grayline {
  display: inline-block;
  width: 100%;
  height: 52px;
  padding: 10px 15px;
  border: 1px solid $light-gray;
  outline: none;
  border-radius: 2px;
  font-size: 15px;
  background: none;
  background: $white;
  color: $black;
  line-height: 30px;
  vertical-align: middle;
  -webkit-appearance: none;

  &::-ms-clear {
    display: none;
  }
  &::-ms-expand {
    display: none;
  }

  &:disabled {
    background: $x-light-gray;
    color: $gray;
  }

  &::-webkit-input-placeholder {
    color: $middle-gray;
  }
  &::-moz-placeholder {
    color: $middle-gray;
  }
  &:-ms-input-placeholder {
    color: $middle-gray;
  }
  &:-moz-placeholder {
    color: $middle-gray;
  }
  &::placeholder {
    color: $middle-gray;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    font-size: 15px;
    border: 1px solid $light-gray;
    -webkit-text-fill-color: $black;
    box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: $white !important;
  }

  select,
  input {
    width: 100%;
    height: 50px;
    padding: 10px 0 10px 15px;
    border: none;
    outline: none;
    background: inherit;
    border-radius: 0;
    font-size: inherit;
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-appearance: none;

    &::-ms-clear {
      display: none;
    }
    &::-ms-expand {
      display: none;
    }

    &:disabled {
      background: $x-light-gray;
      color: $gray;
    }

    &::-webkit-input-placeholder {
      color: $middle-gray;
    }
    &::-moz-placeholder {
      color: $middle-gray;
    }
    &:-ms-input-placeholder {
      color: $middle-gray;
    }
    &:-moz-placeholder {
      color: $middle-gray;
    }
    &::placeholder {
      color: $middle-gray;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      font-size: 15px;
      border: none;
      -webkit-text-fill-color: $black;
      box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0) inset;
      transition: background-color 5000s ease-in-out 0s;
      background-color: $white !important;
    }
  }
}

/* ---------------------------- */
/* ==button==------------------ */
/* ---------------------------- */
%btn-pack {
  display: flex;
  width: auto;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 0;
  text-align: center;
  vertical-align: middle;
  -webkit-appearance: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &.wide {
    width: 100%;
  }
}
