.inviteFriends {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  text-align: center;
  .body {
    padding: 30px 20px;
    color: #000;
    & > h4 {
      font-size: 22px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 14px;
    }

    & > p {
      font-size: 15px;
      line-height: 23px;
    }

    .codeWrap {
      margin: 30px 0;
      padding: 24px 20px;
      border-radius: 10px;
      background-color: rgb(241, 241, 241);
      font-size: 24px;
      line-height: 26px;
    }

    .inviteKakao {
      & > img {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
      }

      & > p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  .footer {
    width: 100%;
    border-top: 1px solid rgb(225, 225, 225);
    padding: 18px 0;
    font-size: 17px;
  }
}
