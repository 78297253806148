.borderLine {
  width: 100%;
  height: 10px;
  background-color: #f7f8f9;

  &.double {
    height: 20px;
  }
  &.thin {
    height: 5px;
  }
}
