.toast {
  width: 100%;
  height: auto;
  text-align: center;
  position: fixed;
  bottom: -50px;
  left: 0;
  opacity: 0;

  p {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgba(51, 51, 51, 0.8);
    border-radius: 50px;
  }
  span {
    display: block;
    line-height: 22px;
    color: #fff;
  }

  &.isShow {
    opacity: 1;
    bottom: 75px;
    z-index: 9999;
    transition: opacity 300ms linear, bottom 300ms linear;
  }
}
