@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 700;
  src: local('Spoqa Han Sans Neo Bold'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Bold.woff2')
      format('woff2'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Bold.woff')
      format('woff'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 500;
  src: local('Spoqa Han Sans Neo Medium'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Medium.woff2')
      format('woff2'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Medium.woff')
      format('woff'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Medium.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 400;
  src: local('Spoqa Han Sans Neo Regular'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Regular.woff2')
      format('woff2'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Regular.woff')
      format('woff'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 300;
  src: local('Spoqa Han Sans Neo Light'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Light.woff2')
      format('woff2'),
    url('https: //common.joongna.com/font/spoqa neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo Light.woff')
      format('woff'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Spoqa Han Sans Neo';
  font-weight: 100;
  src: local('Spoqa Han Sans Neo Thin'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Thin.woff2')
      format('woff2'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Thin.woff')
      format('woff'),
    url('https://common.joongna.com/font/spoqa-neo/SpoqaHanSansNeo_all/SpoqaHanSansNeo_TTF_subset/SpoqaHanSansNeo-Thin.ttf')
      format('truetype');
}
