.dimApp {
  z-index: 1000;

  .dimAppBody {
    background: #fff;
    .dimTextWrap {
      display: flex;
      align-items: center;
      padding: 22px 20px 10px;

      .icon {
        width: 52px;
        height: 52px;
        margin-right: 12px;
      }

      .text {
        display: flex;
        flex-direction: column;

        div {
          letter-spacing: -0.3px;

          font-size: 18px;
          line-height: 20px;
          color: black;
          &:first-child {
            margin-bottom: 6px;
          }
        }
      }
    }

    .buttons {
      padding: 10px 20px 22px;
      text-align: right;
      button {
        height: 36px;
        border-radius: 2px;
        letter-spacing: -0.3px;
        font-size: 14px;
        line-height: 17px;

        &:first-child {
          padding: 9px 27px;
          background-color: #fff;
          color: #979797;
          border: 1px solid #979797;
          margin-right: 6px;
        }
        &:last-child {
          padding: 9px 14px;
          background-color: #ff6900;
          color: white;
          border: 1px solid #ff5e18;
        }
      }
    }
  }
}
