.fullImagesPopup {
  width: 100% !important;
  max-width: 750px !important;
  background: #000;
  min-height: 100vh;
  max-height: 100vh;
  .fullImagesBody {
    overflow-y: scroll;

    > div > div {
      align-items: center;
    }

    .video {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 10;
}

.body {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.closeBtn {
  border-radius: 50%;
  background-color: #000;
  padding: 3px;
  margin: 10px 0 0 10px;
}
