.popUpsWarp {
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  z-index: 500;
  bottom: 0;

  &.normal {
    top: 0;
  }

  &.ios {
    bottom: 0;
  }

  .backGround {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 300ms ease-out;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    width: 90vw;
    max-width: 400px;

    &.clip {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      clip: rect(0, auto, auto, 0);

      & > div > div {
        overflow: hidden;
        overflow-y: auto;
        height: 100vh;
        -webkit-overflow-scrolling: touch;
      }
    }

    &.letDown {
      width: 100vw;
      max-width: 750px;
      max-height: 100vh;
      align-self: flex-end;
    }
  }

  .transitionCont {
    width: 100%;
    &.fadeIn {
      opacity: 0;
      transition: all 300ms ease-out;
    }
    &.slideToRight {
      position: relative;
      left: -100%;
      transition: left 300ms ease-out;
    }
    &.slideToTop {
      transform: translateY(100%);
      transition: transform 300ms ease-out;
    }
  }

  &.isShow {
    .backGround {
      opacity: 1;
      transition: opacity 300ms ease-out;
    }
    .transitionCont {
      &.fadeIn {
        opacity: 1;
        transition: all 300ms ease-out;
      }
      &.slideToRight {
        position: relative;
        left: 0;
        transition: left 300ms ease-out;
      }
      &.slideToTop {
        transform: translateY(0%);
        transition: transform 300ms ease-out;
      }
    }
  }

  &.willHide {
    .backGround {
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms ease-out;
    }
    .transitionCont {
      &.fadeIn {
        opacity: 0;
        transition: all 300ms ease-out;
      }
      &.slideToRight {
        position: relative;
        left: -100%;
        transition: left 300ms ease-out;
      }
      &.slideToTop {
        transform: translateY(100%);
        transition: transform 300ms ease-out;
      }
    }
  }
}
